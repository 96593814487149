import type { FC } from 'react';
import React, { useEffect, useRef } from 'react';

import { useInViewport } from '$lib/hooks/use-in-viewport';

type Props = {
	className?: string;
	onActive: () => void;
};

export const LazyDetector: FC<Props> = (props) => {
	const ref = useRef<HTMLDivElement>(null);
	const [inViewport] = useInViewport(ref);

	useEffect(() => {
		if (inViewport) {
			props.onActive();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inViewport]);

	return <div className={props?.className} ref={ref} />;
};
