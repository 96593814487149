import { showMultiViewer, showViewer } from './Viewer';

export type { MultiViewerProps, ViewerProps } from './Viewer';

const Multi = { show: showMultiViewer };
const Viewer = {
	Multi,
	show: showViewer
};
export default Viewer;
