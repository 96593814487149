import type { ReactElement } from 'react';
import { createPortal } from 'react-dom';

import { resolveContainer } from './get-container';

export type GetContainer = HTMLElement | (() => HTMLElement) | null;

export function renderToContainer(getContainer: GetContainer, node: ReactElement) {
	if (getContainer) {
		const container = resolveContainer(getContainer);
		return createPortal(node, container!);
	}
	return node;
}
