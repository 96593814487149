// import { useSpring } from '@react-spring/web';
// import { FC, useState } from 'react';
// import SwipeableViews from 'react-swipeable-views';

// import IconBack from '$icons/IconBack';

// import { Button } from '../button';
// import { inBrowser } from '../utils/in-browser';
// import styles from './index.module.scss';
// import { Slide } from './Slide';

// export const Slides: FC<{
// 	images: string[];
// 	onTap?: () => void;
// 	maxZoom: number;
// 	defaultIndex: number;
// 	onIndexChange?: (index: number) => void;
// }> = (props) => {
// 	const [currentIndex, setCurrentIndex] = useState(props.defaultIndex);
// 	const slideWidth = inBrowser ? window.innerWidth : 375;
// 	const [{ x }, api] = useSpring(() => ({
// 		x: -props.defaultIndex * slideWidth,
// 		config: { tension: 300 }
// 	}));

// 	return (
// 		<div className={styles[`ypm-viewer-slides`]}>
// 			<div className={styles['ypm-viewer-slides-header']}>
// 				<Button className={styles.button} text rounded>
// 					<IconBack className={styles.icon} />
// 				</Button>

// 				<div className={styles.text}>{`${currentIndex + 1}/${props.images.length}`}</div>
// 			</div>
// 			<SwipeableViews
// 				containerStyle={{ flex: 1, width: '100%', height: '100%' }}
// 				className={styles[`ypm-viewer-slides-inner`]}
// 				onChangeIndex={setCurrentIndex}
// 			>
// 				{props.images.map((image) => (
// 					<Slide
// 						key={image}
// 						image={image}
// 						onTap={props?.onTap}
// 						maxZoom={props.maxZoom}
// 						onZoomChange={(zoom) => {
// 							if (zoom !== 1) {
// 								const index: number = -Math.round(x.get() / slideWidth);
// 								api.start({
// 									x: index * -slideWidth
// 								});
// 							}
// 						}}
// 					/>
// 				))}
// 			</SwipeableViews>
// 		</div>
// 	);
// };

import { animated, easings, useSpring } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import type { CSSProperties, FC } from 'react';

import IconBack from '$icons/IconBack';

import { Button } from '../button';
import { bound } from '../utils/bound';
import { inBrowser } from '../utils/in-browser';
import styles from './index.module.scss';
import { Slide } from './Slide';

const classPrefix = `ypm-viewer`;

export const Slides: FC<{
	files: { url: string; type: 'video' | 'image' | string; poster?: string }[];
	onTap?: () => void;
	maxZoom: number;
	onClickCloseIcon?: () => void;
	defaultIndex: number;
	onIndexChange?: (index: number) => void;
	headerStyles?: CSSProperties;
	bodyStyles?: CSSProperties;
	autoPlay?: boolean
}> = (props) => {
	const slideWidth = inBrowser ? window.innerWidth : 375;
	const [{ x }, api] = useSpring(() => ({
		x: -props.defaultIndex * slideWidth,
		config: { tension: 300, friction: 30, clamp: true, easing: easings.easeInCirc }
	}));

	const count = props.files.length;

	const bind = useDrag(
		(state: any) => {
			const [offsetX] = state.offset;
			if (state.last) {
				const index = bound(
					-Math.round((offsetX + state.velocity[0] * state.direction[0] * 300) / slideWidth),
					0,
					count - 1
				);
				api.start({
					x: index * -slideWidth
				});
			} else {
				api.start({
					x: offsetX
				});
			}
		},
		{
			from: () => [x.get(), 0],
			bounds: () => {
				const index: number = -Math.round(x.get() / slideWidth);
				return {
					right: Math.max(index - 1, 0) * -slideWidth,
					left: Math.min(index + 1, count - 1) * -slideWidth
				};
			},
			rubberband: true,
			axis: 'x'
		}
	);

	return (
		<div className={styles[`${classPrefix}-slides`]} {...bind()} style={props?.bodyStyles}>
			<div style={props?.headerStyles} className={styles['ypm-viewer-slides-header-box']}>
				<div className={styles['ypm-viewer-slides-header']}>
					<Button className={styles.button} text rounded onClick={props.onClickCloseIcon}>
						<IconBack className={styles.icon} />
					</Button>
					<animated.div className={styles.text}>
						{x.to((v: number) => {
							const index: number = -Math.round(v / slideWidth);
							return `${index + 1} / ${count}`;
						})}
					</animated.div>
				</div>
			</div>

			<animated.div className={styles[`${classPrefix}-slides-inner`]} style={{ x }}>
				{props.files.map((file, index) => (
					<Slide
						key={index}
						file={file}
						onTap={props.onTap}
						disableSlide={count <= 1}
						maxZoom={props.maxZoom}
						autoPlay={props.autoPlay}
						onZoomChange={(zoom) => {
							if (zoom !== 1) {
								const index: number = -Math.round(x.get() / slideWidth);
								api.start({
									x: index * -slideWidth
								});
							}
						}}
					/>
				))}
			</animated.div>
		</div>
	);
};
