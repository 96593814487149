import React, {
	createRef,
	CSSProperties,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState
} from 'react';

import { Mask } from '../mask';
import { MaskProps } from '../mask/Mask';
import { renderToBody } from '../utils/render-to-body';
import type { GetContainer } from '../utils/render-to-container';
import { renderToContainer } from '../utils/render-to-container';
import { withDefaultProps } from '../utils/with-default-props';
import styles from './index.module.scss';
import { Slide } from './Slide';
import { Slides } from './Slides';

export type ViewerProps = {
	file?: { url: string; type: 'video' | 'image' | string; poster?: string };
	maxZoom?: number;
	getContainer?: GetContainer;
	visible?: boolean;
	autoPlay?: boolean
	onClose?: () => void;
	afterClose?: () => void;
};

const defaultProps = {
	maxZoom: 3,
	getContainer: null,
	visible: false
};

const Viewer = withDefaultProps(defaultProps)<ViewerProps>((props) => {
	const node = (
		<Mask
			visible={props.visible}
			disableBodyScroll={true}
			opacity={1}
			afterClose={props.afterClose}
		>
			<div className={styles['ypm-viewer-content']}>
				{props.file && (
					<Slide
						file={props.file}
						onTap={() => {
							props.onClose?.();
						}}
						maxZoom={props.maxZoom}
					/>
				)}
			</div>
		</Mask>
	);
	return renderToContainer(props.getContainer, node);
});

export type MultiViewerProps = Omit<ViewerProps, 'files'> &
	MaskProps & {
		files?: { url: string; type: 'video' | 'image' | string; poster?: string }[];
		defaultIndex?: number;
		onIndexChange?: (index: number) => void;
		headerStyles?: CSSProperties;
		bodyStyles?: CSSProperties;
		maskStyle?: CSSProperties;
		autoPlay?: boolean
	};

const multiDefaultProps = {
	...defaultProps,
	defaultIndex: 0
};

const MultiViewer = withDefaultProps(multiDefaultProps)<MultiViewerProps>((props) => {
	const node = (
		<Mask
			visible={props.visible}
			disableBodyScroll={true}
			opacity={1}
			afterClose={props.afterClose}
			style={props?.maskStyle}
		>
			<div className={styles['ypm-viewer-content']}>
				{props.files && (
					<Slides
						headerStyles={props?.headerStyles}
						bodyStyles={props?.bodyStyles}
						onClickCloseIcon={() => props.onClose?.()}
						defaultIndex={props.defaultIndex}
						onIndexChange={props.onIndexChange}
						autoPlay={props.autoPlay}
						files={props.files}
						onTap={() => props.onClose?.()}
						maxZoom={props.maxZoom}
					/>
				)}
			</div>
		</Mask>
	);
	return renderToContainer(props.getContainer, node);
});

export function showViewer(props: Omit<ViewerProps, 'visible'>) {
	type Ref = {
		close: () => void;
	};
	const Wrapper = forwardRef<Ref>((_, ref) => {
		const [visible, setVisible] = useState(false);

		const closeImageViewOnPop = () => {
			unmount?.();
		};
		useEffect(() => {
			setVisible(true);

			window.addEventListener('popstate', closeImageViewOnPop);
			return () => {
				window.removeEventListener('popstate', closeImageViewOnPop);
			};
		}, []);

		useImperativeHandle(ref, () => ({
			close: () => {
				setVisible(false);
			}
		}));
		return (
			<Viewer
				{...props}
				visible={visible}
				onClose={() => {
					props.onClose?.();
					setVisible(false);
				}}
				afterClose={() => {
					props.afterClose?.();
					unmount?.();
				}}
			/>
		);
	});
	const ref = createRef<Ref>();
	const unmount = renderToBody(<Wrapper ref={ref} />);
	return {
		close: () => {
			ref.current?.close();
		}
	};
}

export function showMultiViewer(props: Omit<MultiViewerProps, 'visible'>) {
	type Ref = {
		close: () => void;
	};
	const Wrapper = forwardRef<Ref>((_, ref) => {
		const [visible, setVisible] = useState(false);
		const closeImageViewOnPop = () => {
			unmount?.();
		};
		useEffect(() => {
			setVisible(true);

			window.addEventListener('popstate', closeImageViewOnPop);
			return () => {
				window.removeEventListener('popstate', closeImageViewOnPop);
			};
		}, []);
		useImperativeHandle(ref, () => ({
			close: () => {
				setVisible(false);
			}
		}));
		return (
			<MultiViewer
				{...props}
				visible={visible}
				onClose={() => {
					props.onClose?.();
					setVisible(false);
				}}
				afterClose={() => {
					props.afterClose?.();
					unmount?.();
				}}
			/>
		);
	});
	const ref = createRef<Ref>();
	const unmount = renderToBody(<Wrapper ref={ref} />);
	return {
		close: () => {
			ref.current?.close();
		}
	};
}
