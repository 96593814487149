import 'intersection-observer';

import { MutableRefObject, useEffect, useState } from 'react';

export default function useIsInViewport(target: MutableRefObject<any>) {
	const [isIntersecting, setIsIntersecting] = useState(false);

	useEffect(() => {
		let observer: IntersectionObserver;
		const timer = setTimeout(() => {
			observer = new IntersectionObserver(([entry]) => {
				if ('isIntersecting' in entry) {
					if (!entry.isIntersecting) {
						setIsIntersecting(
							entry.boundingClientRect.top >= -10 &&
								entry.boundingClientRect.top <= window.document.body.clientHeight
						);
					} else {
						setIsIntersecting(true);
					}
				} else {
					setIsIntersecting(
						entry.boundingClientRect.top >= -10 &&
							entry.boundingClientRect.top <= window.document.body.clientHeight
					);
				}
			});
			observer.observe(target.current);
		});

		return () => {
			timer && clearTimeout(timer);
			observer && observer?.disconnect?.();
		};
	}, [target]);

	return [isIntersecting];
}
