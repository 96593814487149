import { animated, useSpring } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import classNames from 'classnames';
import type { FC } from 'react';

import { MImage } from '$components/widget/m-image';
import IconButtonLoading from '$icons/IconButtonLoading';

import styles from './index.module.scss';

type Props = {
	file: {
		url: string;
		type: 'video' | 'image' | string;
		poster?: string;
	};
	maxZoom: number;
	/** 只有一条数据的时候禁止左右滑动 */
	disableSlide?: boolean;
	onTap?: () => void;
	onZoomChange?: (zoom: number) => void;
	autoPlay?: boolean;
};

export const Slide: FC<Props> = (props) => {
	const [{ zoom, x, y }, api] = useSpring(() => ({
		zoom: 1,
		x: 0,
		y: 0,
		config: { tension: 300 }
	}));

	const bind = useGesture(
		{
			onDrag: (state: any) => {
				if (state.tap && state.elapsedTime > 0) {
					// 判断点击时间>0是为了过滤掉非正常操作，例如用户长按选择图片之后的取消操作（也是一次点击）
					props?.onTap?.();

					return;
				}
				const currentZoom = zoom.get();
				if (currentZoom <= 1) {
					api.start({
						x: 0,
						y: 0
					});
				} else {
					const [x, y] = state.offset;
					api.start({
						x,
						y,
						immediate: true
					});
				}
			},
			onPinch: (state: any) => {
				const [d] = state.offset;
				// pinch的rubberband不会自动弹回bound，这里手动实现了
				const zoom = state.last ? Math.max(Math.min(d, props.maxZoom), 1) : d;
				api.start({
					zoom,
					immediate: !state.last
				});
				props.onZoomChange?.(zoom);
				if (state.last && zoom <= 1) {
					api.start({
						x: 0,
						y: 0
					});
				}
			}
		},
		{
			drag: {
				// filterTaps: true,
				from: () => [x.get(), y.get()]
			},
			pinch: {
				distanceBounds: {
					min: 1,
					max: props.maxZoom
				},
				rubberband: true,
				from: () => [zoom.get(), 0]
			}
		}
	);

	return (
		<div
			className={styles[`ypm-viewer-slide`]}
			onPointerMove={(e) => {
				if (zoom.get() !== 1 || props.disableSlide) {
					e.stopPropagation();
				}
			}}
		>
			{props.file.type === 'video' ? (
				<div className={styles[`ypm-viewer-control`]}>
					<div className={classNames(styles[`ypm-viewer-video-wrapper`])}>
						<video
							webkit-playsinline="true"
							x5-video-player-type="h5-page"
							src={props.file.url}
							autoPlay={props.autoPlay ?? true}
							playsInline
							controls
							controlsList="nodownload noplaybackrate nofullscreen"
							disablePictureInPicture
							poster={
								props.file.poster ?? `${props.file.url}?x-oss-process=video/snapshot,t_360,m_fast`
							}
						/>
					</div>
				</div>
			) : (
				<div className={styles[`ypm-viewer-control`]} {...bind()}>
					<animated.div
						className={classNames(styles[`ypm-viewer-image-wrapper`])}
						style={{ scale: zoom, x, y }}
					>
						<MImage
							lazy
							animation
							objectFit="contain"
							src={props.file.url?.replace(/^http:/, 'https:')}
							alt=""
							placeholder={<IconButtonLoading className={styles.progressLoading} />}
							draggable={false}
						/>
					</animated.div>
				</div>
			)}
		</div>
	);
};
