/**
 * @deprecated 后续不再使用此组件 使用Image
 */
import './index.scss';

import classNames from 'classnames';
import React, {
	memo,
	MouseEvent,
	ReactElement,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react';
import { CSSTransition } from 'react-transition-group';

import { browser } from '$lib/utils/tools';

import { LazyDetector } from './LazyLoad';

type IImage = {
	className?: string;
	style?: React.CSSProperties;
	alt?: string;
	src: string;
	srcSet?: string;
	lazy?: boolean;
	webp?: boolean;
	animation?: boolean;
	placeholder?: ReactElement | string;
	objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
	onClick?: (e: MouseEvent) => any;
	children?: ReactElement;
	draggable?: boolean;
	onLoad?: (e: Event) => void;
	onError?: (e: string | Event) => void;
};
const MImage = (props: IImage) => {
	const {
		src,
		style,
		srcSet,
		className,
		animation,
		objectFit = 'cover',
		lazy = false,
		placeholder,
		onLoad,
		onError,
		webp,
		...rest
	} = props;

	const [loaded, setLoaded] = useState(false);
	const [loadComplete, setLoadComplete] = useState(false);
	const [error, setError] = useState(false);
	const [initialize, setInitialize] = useState(false);
	function supportWebp() {
		try {
			return (
				!![].map &&
				document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') == 0
			);
		} catch (err) {
			return false;
		}
	}
	if (browser && !window.supportWebp) {
		window.supportWebp = supportWebp() ? 'support' : 'un_support';
	}
	const _src = useMemo(() => {
		let url = src?.replace(/^http:/, 'https:');
		if (webp && browser && lazy && window.supportWebp === 'support') {
			url += '?x-oss-process=image/format,webp';
		}
		return url;
	}, [lazy, src, webp]);

	useEffect(() => {
		initialize && setInitialize(false);
		error && setError(false);
	}, [_src]);

	let imageProps: Partial<IImage> = {
		...rest,
		style: { ...(style ?? {}), objectFit },
		className: classNames('ypm-image', className)
	};
	const onImageLoad = useCallback(
		(e: Event) => {
			setLoaded(true);
			onLoad?.(e);
		},
		[onLoad]
	);

	const onImageError = useCallback(
		(e: Event | string) => {
			setError(true);
			onError?.(e);
		},
		[onError]
	);

	const onLazyLoad = () => {
		setInitialize(true);
		const image = new Image();
		image.src = _src;
		if (srcSet) image.srcset = srcSet;
		else {
			image.onload = onImageLoad;
			image.onerror = onImageError;
		}
	};
	const renderPlaceholder = () => {
		if (!loaded || error || !loadComplete) {
			if (placeholder) {
				if (React.isValidElement(placeholder)) {
					return React.cloneElement(placeholder as ReactElement);
				}
				return <img {...(imageProps as any)} alt={imageProps.alt} src={placeholder} />;
			}
		}
		return <></>;
	};
	return (
		<div className={classNames('ypm-image', className)}>
			{!initialize && lazy && (
				<LazyDetector
					className={classNames('ypm-image', className)}
					onActive={() => onLazyLoad()}
				/>
			)}
			{lazy && initialize && renderPlaceholder()}
			{!lazy ? (
				<img
					{...(imageProps as any)}
					alt={imageProps.alt}
					src={_src}
					style={{ ...imageProps.style }}
				/>
			) : (
				<CSSTransition in={loadComplete} timeout={animation ? 500 : 0} classNames="ypm-image">
					{loaded && !error ? (
						<img
							{...(imageProps as any)}
							alt={imageProps.alt}
							src={_src}
							onLoad={() => setLoadComplete(true)}
							style={{ ...imageProps.style, display: loadComplete ? '' : 'none' }}
						/>
					) : (
						<React.Fragment />
					)}
				</CSSTransition>
			)}
		</div>
	);
};

export default memo(MImage);
